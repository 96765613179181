import React from 'react';
import TableCell from '@material-ui/core/TableCell';

const SimpleTableCell = props => {
    const {obj} = props;
    let {value} = props;

    if(obj.type === "boolean"){
        value = value ? 'Yes' : 'No';
    }

    return <>
        <TableCell>{value}</TableCell>
    </>;
};

export default SimpleTableCell;