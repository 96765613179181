import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import TableIcon from '@material-ui/icons/Storage';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import CTable from './components/SimpleTable/index';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
}));

function App() {
  const classes = useStyles();

  const tableConfig = {
    headers: [
      {
        title: 'id'
      },
      {
        title: 'Title'
      },
      {
        title: 'Completed'
      }
    ],
    model: [
      {
        object: 'id',
        type: 'string',
      },
      {
        object: 'title',
        type: 'string',
      },
      {
        object: 'completed',
        type: 'boolean',
      }
    ],
    url: 'https://jsonplaceholder.typicode.com/todos'
  };

  return (
    <>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <TableIcon className={classes.icon} />
          <Typography variant="h6" color="inherit" noWrap>
            Simple Table Component
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        <Container maxWidth="md">
          <CTable config={tableConfig} pageSize={10} />
        </Container>
      </main>
    </>
  );
}

export default App;
