import React, {useState, useEffect} from 'react';
import axios from 'axios';
import SimpleTableCell from './Cell';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Pagination from '@material-ui/lab/Pagination';

const SimpleTable = props => {
    const { config, pageSize } = props;

    const [rows, setRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(1);

    const fetchData = async (page = 1) => {
        const res = await axios.get(config['url']);

        setPagesCount(res.data.length / pageSize);

        let elements = res.data;
        let lastIndex = page * pageSize;
        let firstIndex = lastIndex - pageSize;
        elements = elements.slice(firstIndex, lastIndex);

        setRows(elements);
    };

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
        fetchData(value);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return <>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        { config['headers'].map((obj) => (
                            <TableCell key={obj.title}>{obj.title}</TableCell>
                        )) }
                    </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row) => (
                    <TableRow key={row.id}>
                        { config['model'].map((obj, index) => (
                            <SimpleTableCell key={index} obj={obj} value={row[obj.object]}  />
                        )) }
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>

        <div className="paginationWrap">
            <Pagination count={pagesCount} page={currentPage} onChange={handleChangePage} />
        </div>
    </>;
};

export default SimpleTable;